<template>
    <div class="modal" v-if="modelValue" :class="{ active: modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Workout<span>Assigned Plan</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="loader_area2" v-if="loader"><quote-loader /></div>
                <div class="setting_wpr" v-else>
                    <ul class="sorting_card" v-if="workoutPlans.length">
                        <li v-for="(plan, p) of workoutPlans" :key="p">
                            <img :src="plan.thumb ? plan.thumb : require('@/assets/images/thumb/default-playbook.svg')">
                            <div class="card_details">
                                {{ plan.name }}
                            </div>
                        </li>
                    </ul>
                    <div class="empty_area" v-else>
                        <h4>Looks like this Workout not assigned to any plan yet.</h4>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'Assigned Workout',

    data() {
        return {
            loader: false,
        }
    },

    props: {
        modelValue: Boolean,
        selectedWorkout: Object,
    },

    emit: ['update:modelValue'],

    watch: {
        modelValue (value) {
            const vm = this;

            vm.toggleScrollBar(value);

            if (value) {
                vm.loader = true;

                vm.assignedWorkoutPlanList(vm.selectedWorkout.id).then((result) => {
                    vm.loader = false;
                });
            }
        },
    },

    computed: mapState({
        workoutPlans: state => state.workoutModule.assignedWorkoutPlans,
    }),

    methods: {
        ...mapActions({
            assignedWorkoutPlanList: 'workoutModule/assignedWorkoutPlanList',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },
    }
}
</script>

<style>
.sorting_card li {
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.sorting_card li img {
    width: 100px;
    height: auto;
    object-fit: cover;
}

.sorting_card .card_details {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    padding: 10px 15px;
}

.sorting_card .card_details p {
    font-size: 10px;
    line-height: 13px;
    font-weight: 400;
    color: #5a5a5a;
    margin-top: 3px;
}

.sorting_card li:hover {
    background: #fff;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13);
}

.sorting_card li label.capsule_btn {
    justify-content: flex-start;
    padding: 7px 0 0 0;
}

.sorting_card li label.capsule_btn h5 {
    font-size: 12px;
    color: #5a5a5a;
}

.sorting_card li label.capsule_btn div {
    transform: scale(0.7);
}

.empty_area {
    border-radius: 10px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.empty_area h4 {
    font-family: 'Architects Daughter', cursive;
    font-size: 16px;
    line-height: 35px;
    font-weight: 300;
    color: #999;
    max-width: 100%;
    position: relative;
    padding-bottom: 20px;
}
</style>
